@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
html,
body,
#root {
  height: 100%;
  overflow-x: clip;
  color: #252525;
}

body {
  color: #252525;
  background-color: #f0f0f0;
  font-family: "Public Sans", sans-serif;
  overscroll-behavior: none;
}

.pdf-export {
  max-width: 1040px;
  min-width: 1040px !important;
  width: 1040px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
  }
}

a.blank-style-link {
  text-decoration: none;
  color: unset;
}

.menu-icon {
  margin-right: 10px;
  width: 16px;
}

.dropdown-toggle::after {
  display: none !important;
}

.nav-tabs {
  .nav-link {
    border: none;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;

    --bs-nav-link-padding-x: 1rem;
    --bs-nav-link-padding-y: 1.0rem;
    --bs-nav-link-color: #666666;
    --bs-nav-link-disabled-color: #666666;

    &.active {
      border-bottom: solid 2px #252525;
      color: #252525;
    }

  }
}
